/** @format */

import axios from '@axios'

export default {
  namespaced: true,
  state: {
    brandDetail: {},
  },
  mutations: {
    setBrandDetail(state, payload) {
      state.brandDetail = payload
    },
  },
  actions: {
    fetchInvoices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/pro-brand/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editInvoices(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put('/pro-brand/edit', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createInvoices(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/pro-brand/create', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteInvoices(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .delete('/pro-brand/delete', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
