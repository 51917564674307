<template>
  <div>
    <BrandList />
  </div>
</template>

<script>
import BrandList from './BrandList.vue'

export default {
  name: 'Brand',
  components: {
    BrandList,
  },

}
</script>

<style scoped>

</style>
